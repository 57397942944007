import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ReactComponent as Logo } from '../../images/logo.svg';
import JsonConfig from '../../utils/jsonConfig.json';
import { MyContext } from '../../Context/MyContext';
import { VITALITY_LOGO } from "../../utils/Images";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    logoSeparation: {
        paddingTop: "30px",
        paddingBottom: "60px",
    },
    logoSeperationPDCO: {
        paddingTop: "30px",
    },
    subHeader: {
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "24px",
        margin: 0,
        paddingTop: "16px",
        paddingBottom: "16px",
        color: "#282B3E",
        paddingLeft: "32px"
    },
    subHeader_container: {
        marginTop: "16px",
        marginLeft: "-32px",
        paddingBottom: "60px",
    },
    subheader_background: {
        width: "175%",
        backgroundColor: "#EDEDED",
        paddingTop: "6px",
        paddingBottom: "8px",
        display: "flex"

    },

}));

const Header = props => {
    const classes = useStyles();

    const displayPDCOHeader = () => {
        if (JsonConfig.app_id.PDCO === props.appId) {
            return true;
        }
        return false;
    }

    const getVitalityLoginUrl = () => {
        const REACT_APP_VITALITY_URL = process.env.REACT_APP_VITALITY_URL;
        if (REACT_APP_VITALITY_URL.startsWith("https://test.johnhancockvitality.com") ||
            REACT_APP_VITALITY_URL.startsWith("https://stage.johnhancockvitality.com") ||
            REACT_APP_VITALITY_URL.startsWith("https://johnhancockvitality.com")) {
            return REACT_APP_VITALITY_URL;
        }

        return "";
    }

    return (
        <div data-testid="header-root" id="header-root" >
        <Grid container spacing={12}>
            <Grid id="header-logo" item xs={12} className={displayPDCOHeader() ? classes.logoSeperationPDCO : classes.logoSeparation}>
                {
                    props.appId === JsonConfig.app_id.VITALITY ?
                        <a href={getVitalityLoginUrl()}>
                            <img width={265} src={VITALITY_LOGO} alt="Vitality" />
                        </a> :
                        <Logo />
                }


            </Grid>
        </Grid>
        <div>
        </div>
        {displayPDCOHeader() && (
            <Grid container className={classes.subHeader_container} spacing={12}>
                <Grid item xs={12} className={classes.root}>
                    <div className={classes.subheader_background}>
                        <p className={classes.subHeader}>Life insurance policy activation</p>
                    </div>
                </Grid>
            </Grid>
        )}
    </div>
    )
}

export default Header;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as JHLogoDesktop } from '../../images/jh_header_logo_desktop.svg';
import { ReactComponent as JHLogoMobile } from '../../images/jh_header_logo_mobile.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    logoSeparation: {
        paddingTop: "30px",
        paddingBottom: "60px",
        // [theme.breakpoints.up("md")]: {
        //     paddingBottom: "80px",
        // },
    },
    logoSeperationPDCO: {
        paddingTop: "30px",
    },
    logoDesktop: {
        display: "inline-block",
        [theme.breakpoints.only("xs")]: {
            display: "none"
        },
    },
    logoMobile: {
        display: "none",
        [theme.breakpoints.only("xs")]: {
            display: "inline-block"
        },
    },
    subHeader: {
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "24px",
        margin: 0,
        paddingTop: "16px",
        paddingBottom: "16px",
        color: "#282B3E",
    },
    subHeader_container: {
        marginTop: "16px",
        marginLeft: "0px",
    },
    subheader_background: {
        width: "175%",
        backgroundColor: "#EDEDED",
        paddingTop: "6px",
        paddingBottom: "8px",
        display: "flex"

    },
    headerContainer: {
        marginBottom: "60px",
         [theme.breakpoints.up("xs")]: {
            marginBottom: "40px",
        },
    }
}));

const PDCOHeader = props => {
    const classes = useStyles();

    return (
        <div data-testid="header-root" id="header-root" className={classes.headerContainer}>
            <Container>
                <Grid id="header-logo" item xs={12} className={classes.logoSeperationPDCO}>
                    <JHLogoDesktop className={classes.logoDesktop} />
                    <JHLogoMobile className={classes.logoMobile} />
                </Grid>
            </Container>
            <div>
            </div>
            {props.displayPDCOBanner && (
                <Grid container className={classes.subHeader_container} spacing={12}>
                    <div className={classes.subheader_background}>
                        <Container>
                            <p className={classes.subHeader}>Life insurance policy activation</p>
                        </Container>
                    </div>
                </Grid>
            )}
        </div>

    )
}

export default PDCOHeader;